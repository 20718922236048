.mc-flights-nav .ant-menu-submenu > .ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    height: 48px;
}

.mc-flights-nav {
    .mc-flights-nav-submenu-manifesting {
        .anticon {
            color: rgb(106, 169, 243);
        }

        .ant-menu-submenu-title {
            background: linear-gradient(90deg, rgba(106, 169, 243, 0.1), transparent)
        }
    }

    .mc-flights-nav-submenu-flytwatch {
        .anticon {
            color: rgb(241, 243, 106);
        }

        .ant-menu-submenu-title {
            background: linear-gradient(90deg, rgba(241, 243, 106, 0.1), transparent)
        }
    }

    .mc-flights-nav-submenu-report {
        .anticon {
            color: rgb(243, 172, 106);
        }

        .ant-menu-submenu-title {
            background: linear-gradient(90deg, rgba(243, 172, 106, 0.1), transparent)
        }
    }

    .mc-flights-nav-submenu-masterdata {
        .anticon {
            color: rgb(122, 243, 106);
        }

        .ant-menu-submenu-title {
            background: linear-gradient(90deg, rgba(122, 243, 106, 0.1), transparent)
        }
    }

    .mc-flights-nav-submenu-management {
        .anticon {
            color: rgb(200, 106, 243);
        }

        .ant-menu-submenu-title {
            background: linear-gradient(90deg, rgba(200, 106, 243, 0.1), transparent)
        }
    }
}

.mc-flights-nav.ant-layout-sider-collapsed .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 50px;
}